import React from "react"
import Layout from "../components/layout"
import HeadLabel from "../components/head-label"
import Seo from "../components/seo"

export default function Thanks() {
  return (
    <Layout>
      <Seo Robots="noindex" />
      <div className="container px-5 py-12 mx-auto max-w-4xl">
        <HeadLabel label={"お問い合わせありがとうございます"} />
        <div>担当者より折り返しご連絡させていただきます。</div>
      </div>
    </Layout>
  )
}
