import React from "react"

export default function HeadLabel({
  label,
  id = "",
  color = "before:border-gray-800",
}) {
  return (
    <div
      id={id}
      className={
        `flex items-center text-lg my-4 before:border-t-2 ` +
        color +
        ` before:content-[''] before:w-20 before:mx-2`
      }
    >
      {label}
    </div>
  )
}
